<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="组织名称" prop="orgId">
						<Select
							v-model="formData.orgId"
							:label-in-value="true"
							filterable
							clearable
							placeholder="请选择"
						>
							<Option
								:value="list.id"
								v-for="list in orgLists"
								:key="list.id"
								:label="list.name"
								style="width: 318px"
							>
								{{ list.name }}
							</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="部门/科室名称" prop="name">
						<Input
							:maxlength="64"
							v-model.trim="formData.name"
							placeholder="请输入部门/科室名称"
						/>
					</FormItem>
				</Col>
			</Row>
			<ExtraForm
				@extraValueChange="(key, value) => handleExtraValueChange(key, value)"
				:list="extraList"
				:data="formData"
			></ExtraForm>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/department"
import ExtraForm from "@/components/BusinessComponents/ExtendForm/ExtraForm.vue"
import ExtraMixin from "@/mixins/ExtraMixin"

const { apiGet, apiAdd, apiUpdate } = api

export default {
	name: "Add",
	props: ["visible", "updateId", "orgLists"],
	mixins: [ExtraMixin],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				name: { required: true, message: "名称不能为空", trigger: "blur" },
				orgId: { required: true, message: "组织名称不能为空", trigger: "blur" }
			},
			extraList: []
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.title = "新增部门/科室"
				this.formData = {}
				if (this.updateId) {
					this.title = "修改部门/科室"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.updateId })
						if (res) {
							this.formData = {
								...this.formData,
								...res.data,
								...JSON.parse(res.data.extraData)
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							res = await apiUpdate({
								...this.formData,
								id: this.updateId,
								extraData: this.getExtraData()
							})
						} else {
							res = await apiAdd({
								...this.formData,
								extraData: this.getExtraData()
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	},
	components: {
		ExtraForm
	},
	created() {
		this.getExtraList("DEPARTMENT")
	}
}
</script>

<style scoped>
.ivu-select-dropdown .ivu-select-item {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
